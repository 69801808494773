// in src/Comments.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  DateField,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  ReferenceManyField,
  SelectInput,
  ListActions,
  ArrayField,
  SingleFieldList
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const answerFilters = [
  <TextInput label="Rechercher" source="instruction" alwaysOn />,
  <SelectInput label="Catégorie" source="category_id" alwaysOn choices={[
      { id: 'Eo4HxIve95CTpW3GHfmb', name: 'Test de grammaire/orthographe/vocabulaire' },
      { id: 'Qbt4GWrqCVMDHGfYWei2', name: 'Test de logique' },
      { id: 'YgGVx9O73F9v7bfuP2Ci', name: 'Rédaction' },
    ]} />
];

export const AnswerList = (props) => (
  <List {...props}
    filters={answerFilters}  
    perPage={25} 
    actions={<ListActions/>}   
  >
    <Datagrid>  
      
        <TextField source="correct" />
        <TextField source="time" />
     
      <ShowButton label="" />
    </Datagrid>
  </List>
  
);

export const AnswerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <RichTextField source="body" />
      <DateField source="createdate" options={{ disabled: true }} />
      <DateField source="lastupdate" options={{ disabled: true }} />
    </SimpleShowLayout>
  </Show>
);