// in src/Comments.js
import * as React from "react";
import { Fragment } from "react";
import Loader from "react-js-loader";
import { useState } from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  TextField,
  ShowButton,
  RichTextField,
  ReferenceManyField,
  SingleFieldList,
  TabbedShowLayout,
  Tab,
  ChipField,
  ReferenceField,
  TopToolbar,
  ListButton,
  ExportButton,
  ListActions,
  BooleanField,
  TextInput,
  Button,
  SimpleShowLayout,
  Query,
  DeleteButton,
} from "react-admin";

import DownloadIcon from '@material-ui/icons/GetApp';
import { getFrenchAnswers, getLogicAnswers, getRedactionAnswers, downloadPDF } from './pdfService';

const ListActionsQuestions = (props) => (
  <TopToolbar>
      
  </TopToolbar>
);

const ShowListActions = (props) => {

  async function downloadFile() {
    setShowLoader(true);
    const frenchAnswers = await getFrenchAnswers(props.data.id);
    const logicAnswers = await getLogicAnswers(props.data.id);
    const redactionAnswers = await getRedactionAnswers(props.data.id);
    downloadPDF(frenchAnswers, logicAnswers, redactionAnswers, props.data)
    setShowLoader(false);
  }

  const [showLoader, setShowLoader] = useState(false);
 
  const CustomLoader = () => 
    <Loader 
      type="bubble-top" 
      bgColor={"#0f6e8b"}  
      color={"#0f6e8b"}  
      title={"Téléchargement en cours"} 
      size={250} 
  />;

  return (
    <TopToolbar>
      <ListButton label="Revenir à la liste des utilisateurs"/>
      <Button children={<DownloadIcon/>} label="Télécharger" onClick={downloadFile}></Button>
      {showLoader ? <CustomLoader/> : null}
    </TopToolbar>
  )
};

const UserBulkActionButtons = props => (
  <Fragment>
  </Fragment>
);

const userFilters = [
  <TextInput label="Rechercher courriel" source="email" alwaysOn />,
  <TextInput label="Rechercher prénom" source="firstname" alwaysOn />,
  <TextInput label="Rechercher nom" source="lastname" alwaysOn />
]

export const UserList = (props) => (
  <List 
    {...props}
    bulkActionButtons={<UserBulkActionButtons />}
    filters={userFilters}  
    perPage={25} 
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid>
      <TextField label="Courriel" source="email" />
      <TextField label="Prénom" source="firstname" />
      <TextField label="Nom" source="lastname" />
      <TextField label="Bonnes réponses" source="nbGoodAnswers" />
      <TextField label="Nombre de questions" source="nbQuestions" />
      <ShowButton label="" />
      <DeleteButton/>
    </Datagrid>
  </List>
  
);

export const UserShow = (props) => (
  <Show 
    {...props}
    actions={<ShowListActions/>}
  >
    <SimpleShowLayout>
      <TextField label="Courriel" source="id" />
      <TextField label="Prénom" source="firstname" />
      <TextField label="Nom" source="lastname" />
      <List
        perPage={40}
        actions={<ListActionsQuestions/>}
      >
        <ReferenceManyField label="Réponse" reference="answers" target="user_id">
            <Datagrid>
              <ReferenceField link="show" label="Question" source="question_id" reference="questions">
                <TextField source="instruction" />
              </ReferenceField>
              <TextField label="Réponse de l'utilisateur" source="answer" />
              <ReferenceField link={false} label="Réponse attendue" source="question_id" reference="questions">
                <TextField  source="correct_answer" />
              </ReferenceField>
              <BooleanField label="Résultat" source="correct" />
            </Datagrid>
        </ReferenceManyField>
      </List>
    </SimpleShowLayout>
  </Show>
);



