import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyB-tByzSk1Xob9_mTfD3-MRPJwA2oW1iMg",
    authDomain: "quiz-groupama.firebaseapp.com",
    projectId: "quiz-groupama",
    databaseURL: "https://quiz-groupama.firebaseio.com/",
    storageBucket: "quiz-groupama.appspot.com",
    messagingSenderId: "424248965375",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;