import * as React from "react";
import { theme } from "./theme";

import { UserList, UserShow } from "./users";
import { CategorieList, CategorieShow} from "./categories";
import { AnswerList, AnswerShow } from "./answers";
import { QuestionList, QuestionShow } from "./questions";

import UserIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Apps';
import QuestionIcon from '@material-ui/icons/Help';
import CategoryIcon from '@material-ui/icons/Category';

import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider
} from "react-admin-firebase";

import CustomLoginPage from './CustomLoginPage';

import { firebaseConfig as config } from './FIREBASE_CONFIG';

import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const options = {
  logging: true,
  rootRef: ''
}

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

class App extends React.Component {
  render() {
    return (
      
      <Admin
        theme={theme}
        loginPage={CustomLoginPage} 
        dataProvider={dataProvider}
        authProvider={authProvider}
        locale="fr"
        i18nProvider={i18nProvider}
      >
        <Resource
          options={{ label: 'Utilisateurs' }}
          name="users"
          icon={UserIcon}
          list={UserList}
          show={UserShow}
        />
        <Resource
          options={{ label: 'Catégories' }}
          name="categories"
          icon={CategoryIcon}
          list={CategorieList}
          show={CategorieShow}
        />
        <Resource
          options={{ label: 'Questions' }}
          name="questions"
          icon={QuestionIcon}
          list={QuestionList}
          show={QuestionShow}
        >
        </Resource>
        <Resource
          options={{ label: 'Tableau de bord' }}
          name="answers"
          icon={DashboardIcon}
          list={AnswerList}
          show={AnswerShow}
        />
      </Admin>
    );
  }
}

export default App;
