// in src/Comments.js
import * as React from "react";
import IconEvent from '@material-ui/icons/Event';

// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  RichTextField,
  SelectInput,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";


const ListActions = (props) => (
  <TopToolbar>
      
  </TopToolbar>
);

const questionFilters = [
  <TextInput label="Rechercher" source="instruction" alwaysOn />,
  <SelectInput label="Catégorie" source="category_id" alwaysOn choices={[
      { id: 'Eo4HxIve95CTpW3GHfmb', name: 'Test de grammaire/orthographe/vocabulaire' },
      { id: 'Qbt4GWrqCVMDHGfYWei2', name: 'Test de logique' },
      { id: 'YgGVx9O73F9v7bfuP2Ci', name: 'Rédaction' },
    ]} />
];

export const QuestionList = (props) => (
  <List {...props}
    filters={questionFilters}  
    perPage={25} 
    actions={<ListActions/>}   
  >
    <Datagrid>
      <TextField label="Identifiant question" source="id" />
      <ReferenceField label="Categorie" source="category_id" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <RichTextField source="instruction" />
      
      <ArrayField label="Réponses" source="answers">
        <SingleFieldList>
          <TextField />
        </SingleFieldList>
      </ArrayField>
      
      <TextField  label="Bonne réponse" source="correct_answer" /> 
      <TextField label="Temps" source="time" />
      <ShowButton label="" />
    </Datagrid>
  </List>
  
);

export const QuestionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Identifiant question" source="id" />
      <ReferenceField label="Categorie" source="category_id" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <RichTextField source="instruction" />
      
      <ArrayField label="Réponses" source="answers">
        <SingleFieldList>
          <TextField />
        </SingleFieldList>
      </ArrayField>
      
      <TextField  label="Bonne réponse" source="correct_answer" /> 
      <TextField label="Temps" source="time" />
      
    </SimpleShowLayout>
  </Show>
);


