import { GET_ONE } from 'react-admin';
import db from './firebase.config';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import { optimaLogo } from './optima';

export const getFrenchAnswers = async function(userId) {
    const answers = [];
    const AnswersSnapshot = await db.collection('answers').where('user_id', '==',  userId).get()
    for (const answer of AnswersSnapshot.docs) {
        const QuestionsSnapshot = await db.collection('questions').where('id', '==', answer.data().question_id).get()
        for (const question of QuestionsSnapshot.docs) {
            if (question.data().category_id == 'Eo4HxIve95CTpW3GHfmb') {
                var answerData = answer.data()
                delete answerData.question_id
                const formatedQuestion = question.data().context ? question.data().instruction + ' ' + question.data().context: question.data().instruction
                const result = answerData.correct ? "Vrai" : "Faux";
                var tmp = {
                    question: formatedQuestion,
                    answer: answerData.answer,
                    correct_answer: question.data().correct_answer,
                    result: result
                }
                answers.push(tmp)
            }
        };
    };
    return answers;
};

export const getLogicAnswers = async function(userId) {
    const answers = [];
    const AnswersSnapshot = await db.collection('answers').where('user_id', '==',  userId).get()
    for (const answer of AnswersSnapshot.docs) {
        const QuestionsSnapshot = await db.collection('questions').where('id', '==', answer.data().question_id).get()
        for (const question of QuestionsSnapshot.docs) {
            if (question.data().category_id == 'Qbt4GWrqCVMDHGfYWei2') {
                var answerData = answer.data()
                delete answerData.question_id
                const formatedQuestion = question.data().context ? question.data().instruction + ' ' + question.data().context: question.data().instruction
                console.log(typeof answerData.correct)
                const result = answerData.correct ? 'Vrai' : 'Faux';
                var tmp = {
                    question: formatedQuestion,
                    answer: answerData.answer,
                    correct_answer: question.data().correct_answer,
                    result: result
                }
                answers.push(tmp)
            }
        };
    };
    return answers;
};

export const getRedactionAnswers = async function(userId) {
    const answers = [];
    const AnswersSnapshot = await db.collection('answers').where('user_id', '==',  userId).get()
    for (const answer of AnswersSnapshot.docs) {
        const QuestionsSnapshot = await db.collection('questions').where('id', '==', answer.data().question_id).get()
        for (const question of QuestionsSnapshot.docs) {
            if (question.data().category_id == 'YgGVx9O73F9v7bfuP2Ci') {
                var answerData = answer.data()
                delete answerData.question_id
                var tmp = {
                    question: question.data().instruction + ' ' + question.data().context,
                    answer: answerData.answer,
                    correct_answer: question.data().correct_answer,
                    result: answerData.correct
                }
                answers.push(tmp)
            }
        };
    };
    return answers;
};

export const addUserInformations = async function(doc, user) {
    doc.text(15, 110, `Prénom: ${user.firstname}`)
    doc.text(15, 120, `Nom: ${user.lastname}`)
    doc.text(15, 130, `Email: ${user.email}`)
    return doc;
}

export const addUserRecap = async function(doc, user) {
    doc.autoTable({
        margin: { top: 140 },
        head: [["Nombre de questions répondues", "Nombre de bonnes réponses"]],
        body: [{
            nbQuestions: user.nbQuestions,
            nbGoodAnswers: user.nbGoodAnswers
        }],
        headStyles: { fillColor: [46, 108, 135] },
        columns: [
            {
                header: "Nombre de questions répondues",
                dataKey: "nbQuestions"
            },
            {
                header: "Nombre de bonnes réponses",
                dataKey: "nbGoodAnswers"
            },
        ]
    })
    return doc;
}

export const addUserFrenchQuestions = async function(doc, answers) {
    doc.addPage();
    doc.text(15, 20, `Questions de Français`)
    doc.autoTable({
        margin: { top: 30 },
        head: [["Question", "Réponse de l'utilisateur", "Réponse attendue", "Résultat"]],
        body: answers,
        headStyles: { fillColor: [46, 108, 135] },
        columns: [
            {
                header: "Question",
                dataKey: "question"
            },
            {
                header: "Réponse de l'utilisateur",
                dataKey: "answer"
            },
            {
                header: "Réponse attendue",
                dataKey: "correct_answer"
            },
            {
                header: "Résultat",
                dataKey: "result"
            }
        ]
    })
    return doc;
}

export const addUserRedaction = async function(doc, answers) {
    doc.addPage();
    doc.text(15, 20, `Rédaction`)
    doc.autoTable({
        margin: { top: 30 },
        head: [["Énoncé"]],
        body: answers,
        headStyles: { fillColor: [46, 108, 135] },
        columns: [
            {
                header: "Énoncé",
                dataKey: "question"
            }
        ]
    })

    doc.autoTable({
        margin: { top: 50 },
        head: [["Réponse de l'utilisateur"]],
        body: answers,
        headStyles: { fillColor: [46, 108, 135] },
        columns: [
            {
                header: "Réponse de l'utilisateur",
                dataKey: "answer"
            },
        ]
    })
    return doc;
}

export const addUserLogicQuestions = async function(doc, answers) {
    doc.addPage();
    doc.text(15, 20, `Questions de logique`)
    doc.autoTable({
        margin: { top: 30 },
        head: [["Question", "Réponse de l'utilisateur", "Réponse attendue", "Résultat"]],
        body: answers,
        headStyles: { fillColor: [46, 108, 135] },
        columns: [
            {
                header: "Question",
                dataKey: "question"
            },
            {
                header: "Réponse de l'utilisateur",
                dataKey: "answer"
            },
            {
                header: "Réponse attendue",
                dataKey: "correct_answer"
            },
            {
                header: "Résultat",
                dataKey: "result"
            }
        ]
    })
    return doc;
}

export const downloadPDF = async function(frenchAnswers, logicAnswers, redactionAnswers, user) {
    var doc = new jsPDF();

    doc.addImage(optimaLogo, 'JPEG', 75, 10, 65, 60);
    doc = await addUserInformations(doc, user);
    doc = await addUserRecap(doc, user);
    doc = await addUserFrenchQuestions(doc, frenchAnswers);
    doc = await addUserLogicQuestions(doc, logicAnswers);
    doc = await addUserRedaction(doc, redactionAnswers);
    doc.save(`Résultat questionnaire Groupama - ${user.firstname} ${user.lastname}`)
}
