import { defaultTheme } from "react-admin";
import { createMuiTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";

export const theme = createMuiTheme(
  merge({}, defaultTheme, {
    palette: {
      // Your theme goes here
      // Write the following code to have an orange app bar. We'll explain it later in this article.
      primary: {
        main: "#0f6e8b", // 
      },
      secondary: {
        main: "#0f6e8b", // 
      },
    }
  })
);