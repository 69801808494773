// in src/Comments.js
import * as React from "react";
import { Fragment } from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  DateField,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  TopToolbar,
  EditButton,
  DeleteButton,
  RichTextField,
  ReferenceField,
  ReferenceManyField,
  SelectInput
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const CategorieBulkActionButtons = props => (
  <Fragment>
  </Fragment>
);

const ListActions = (props) => (
  <TopToolbar>
      
  </TopToolbar>
);

export const CategorieList = (props) => (
  
  <List 
    {...props}
    actions={<ListActions/>}   
    bulkActionButtons={<CategorieBulkActionButtons />}  
  >
    <Datagrid>
      <TextField Label="Catégorie" source="name" />
      <TextField source="description" />
      <ShowButton label="" />
    </Datagrid>
  </List>
);




export const CategorieShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField Label="Catégorie"  source="name" />
      <TextField Label="Description" source="description" />
      <ReferenceManyField label="Questions" target="category_id" reference="questions">
        <Datagrid>
          <ReferenceField label="Question" source="category_id" reference="questions">
            <TextField source="instruction" />
          </ReferenceField>  
          <TextField label="" source="instruction" />
        </Datagrid>
      </ReferenceManyField>
    
    </SimpleShowLayout>
  </Show>
);

